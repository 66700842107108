<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-alert
          show
          variant="danger"
          v-if="error_message.length > 0"
        >
          <div class="alert-body">
            <div class="my-50" v-for="(item, index) in error_message" :key="index">
              <feather-icon class="mr-25" icon="XIcon" />
              <span class="ml-25">{{ item }}</span>
            </div>
          </div>
        </b-alert>
      </b-col>
      <b-col cols="12" md="6">
        <a href="/attachment/import_member_template.xlsx">
          <b-button variant="primary" class="mb-2">Download Template</b-button>
        </a>
        <b-card-code
          no-body 
          title="Import">
          <b-card-body>
            <b-row>
              <b-col>
                <b-form-group label="Pilih Corporate" label-for="corporate" v-if="role === 'superadmin'">
                  <b-form-select 
                    id="corporate"
                    v-model="dataInput.corporate"
                    :options="corporate"
                    @change="getDivisiByCorporate()"
                  />
                </b-form-group>
                <b-form-group label="Pilih Divisi" label-for="divisi">
                  <b-form-select 
                    id="divisi"
                    v-model="dataInput.divisi"
                    :options="divisi"
                  />
                </b-form-group>
                <b-form-group label="Upload File" label-for="upload-file">
                  <b-form-file
                    v-model="dataInput.file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mt-2">
                <b-button variant="primary" @click="submit()">Import</b-button>
                <b-button variant="secondary" class="ml-1" @click="gotoMember()">Cancel</b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card-code>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
		BRow, BCol,
    BCard, BCardBody,
    BFormGroup, BFormSelect, BFormFile, BButton, BAlert
  } from 'bootstrap-vue'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
			BRow, BCol,
      BCardCode, BCard, BCardBody,
      BFormGroup, BFormSelect, BFormFile, BButton, BAlert,
      ToastificationContent
    },
    data() {
      return {
        dataInput: {
          corporate: null,
          divisi: null,
          file: null
        },
        divisi: [],
        active_role: null,
        error_message: [],
        role: localStorage.getItem('role')
      }
    },
    computed: {
      corporate() {
        const corporate = this.$store.state.corporate.corporate.map((item) => {
          return {
            value: item.id,
            text: item.name
          }  
        })

				return corporate
			}
    },
		created() {
      this.checkAuth()
    },
    mounted() {
      this.active_role = localStorage.getItem('role')
      this.getDataDivisi()
			this.getDataCorporate()
    },
    methods: {
      async getDataDivisi() {
        let cid = localStorage.getItem('client_id')
				
				let dataAPI = null
        if(this.active_role == 'corporate') {
          const config = {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
          }
          dataAPI = await this.$axios.get(`${cid}/member/corporate/divisi`, config)
        }

        dataAPI.data.data.forEach(item => {
          let divisi_item = {
            value: item.id,
            text: item.divisi_name,
          }

          this.divisi.push(divisi_item)
        })
      },
      getDataCorporate() {
				this.$store.dispatch('corporate/loadCorporate')
					.then(res => {})
					.catch(error => {})
			},
      async getDivisiByCorporate() {
        const cid = localStorage.getItem('client_id')
        const corporateId = this.dataInput.corporate
        const config = {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }

        const dataAPI = await this.$axios.get(`${cid}/member/corporate/divisi_by_corporate/${corporateId}`, config)

        if(dataAPI.status === 200) {
          const divisi = dataAPI.data.data
          this.divisi = divisi.map(item => {
            return {
              value: item.id,
              text: item.divisi_name
            }
          })
        }
      },

      gotoMember() {
        this.$router.push({ path: `/user/member` })
      },

      submit() {
        this.error_message = []
        let cid = localStorage.getItem('client_id')

        if(this.dataInput.file == null) {
          this.runToast('error', 'File wajib diisi')
        } else {
          if(this.dataInput.divisi == null) {
            this.runToast('error', 'Divisi wajib diisi')
          } else {
            const data = new FormData()
            data.append('file', this.dataInput.file)

            if(this.active_role != 'corporate') {
              data.append('corporate', this.dataInput.corporate)
            }

            data.append('divisi', this.dataInput.divisi)

            const config = {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
              }
            }
            
            this.$axios.post(`${cid}/member/import`, data, config)
              .then(res => {
                this.runToast('success', 'Import Data Success')

                setTimeout(() => {
                  this.$router.push({ path: '/user/member' })
                }, 3000);
              })
              .catch(error => {
                let error_res = error.response.data.data

                let grouping_data = []
                error_res.forEach((item) => {
                  const { row } = item

                  grouping_data[row] = grouping_data[row] ?? []

                  // Set up error message
                  grouping_data[row].push({
                    row: item.row,
                    message: item.errors[0],
                    name: `${item.values.first_name} ${item.values.last_name}`
                  })
                })

                let result = grouping_data.map((item) => {
                  let row = null
                  let message = []
                  let name = ""

                  item.forEach((item) => {
                    row = item.row
                    message.push(item.message)
                    name = item.name
                  })

                  let join_array = message.join()
                  let replace_dot = join_array.replaceAll(".", "")
                  let replace_comma = replace_dot.replaceAll(",", ", ")
                  let set_lower = replace_comma.toLowerCase()

                  return `Error in row ${row} - ${name}, reason : ${set_lower}`
                })

                // convert from object to array
                const convert_obj_to_arr = Object.values(result)

                this.error_message = convert_obj_to_arr
              })
          }
        }
      },
      runToast(type, msg) {
        let title = null
        let icon = null
        let variant = null

        if(type == 'error') {
          title = 'Error'
          icon = 'XIcon'
          variant = 'danger'
        } else if(type == 'success') {
          title = 'Success'
          icon = 'CheckIcon'
          variant = 'success'
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: msg,
            variant: variant
          },
        },{
          position: 'bottom-right'
        })
      }
    }
  }
</script>